import React, { useState, useEffect } from "react";
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import Layout from "../components/layout";
import SEO from "../components/seo"
import Articles from '../components/articles';

// Styles
// import "../sass/main.scss"
import styles from "../sass/pages/services.module.scss"

import icon1 from "../assets/img/services-icons/icon-1-paintbrush.svg"
import icon2 from "../assets/img/services-icons/icon-2-coding.svg"
import icon3 from "../assets/img/services-icons/icon-3-clock.svg"
import icon4 from "../assets/img/services-icons/icon-4-app.svg"
import icon5 from "../assets/img/services-icons/icon-5-message.svg"


let lang = ""

const Services = () => {

  lang = useIntl().locale;

  const data = useStaticQuery(graphql`
    query ImagesServices {
      groupPicture: file( relativePath: { eq: "groupPicture.png"} ) {
          id
          childImageSharp { 
            fixed {
              ...GatsbyImageSharpFixed
            }
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
  `);

  const title = useIntl().formatMessage({id: 'services'});  

  const [load] = useState(0);

  return (
    <Layout color="">
      <SEO 
      lang={lang}
      title={title}
      /> 
      <article className={ 'container-small ' + styles.article }>
        
      <div className={'grid-cards-2' + ' ' + 'space'}>
        <div>
            <h2>When you don't know where to start</h2> <br/>
            <p>Sometimes, you know you need to implement a change but it’s hard to figure out the right one or even how to do it. <br/>
            <br />Most businesses leaders know precisely the challenges they face and why they are experiencing trouble. They have done the right diagnosis, but are unsure about the cure. <br/>
            <br />The temptation is to jump into the first possible solution that appears after a quick google search. <br/>
            <br />But businesses that allow themselves to rumble with challenges, reap the benefits of true innovation. <br/>
            <br />For those businesses we have to offer:</p>
        </div>
        <div className="cardLarge">
            <div className="cardContent">
              <p className="cardHashtags">#hashtags</p>
              <p className="cardTitle">A new way of buying tickets online</p>
            </div>
        </div>
      </div>

      <div className={'grid-cards-2 '}>
        <div>
          <h3>Discovery process and Design sprints</h3>
          <p>The road to a great solution starts with a deep dive into the problem. Our design methodology is inspired by thought leaders and curated to adapt to our clients times and needs. When embarking on a discovery journey we will reflect about your business and your clients, researching and ideating new ways of providing value to your customers.</p>
          </div>
        <div>
          <h3>Lo/Hi fidelity prototyping</h3>
          <p>After coming up with an idea, creating prototypes is the best way of testing their whole potential with a low investment. It doesn’t matter if we are creating a new app or designing a new layout for your service, prototyping will allow you to measure the potential and better calculate the cost of actually moving forward.</p>
        </div>
        <div>
          <h3>MVP definition</h3>
          <p>In the ideation process, we like to focus on big ideas. But when it comes to implementation it is best to start with a small version of the product. By understanding the true potential of the product, we design and create a minimal viable product to rapidly go to market.</p>
        </div>
        <div>
          <h3>User interviews and surveys</h3>
          <p>When in doubt, go straight to the source. We conduct different types of user research to capture key insights that inspire new points of view and solutions.</p>
        </div>
      </div>
      
      </article>
      <div className={styles.blueLine}></div>
      <article className={ styles.article }>  

        <div className={'space'}>
        <div className={'container-small'}>
        <div className={'grid-cards-2'}>
        <div>
          <h2>When you are ready to go</h2>
          <p>When our clients have a concrete idea of what they want to build - or after we arrive at that idea together-, we provide all the right team for the challenge.</p>
        </div>
        </div>
       
                
        <div className={'grid-cards-5 '+'space'}>
          <div className={'whiteCard  left'}>
            <div className={'whiteCardIconLeft'}><img src={icon1} alt="" /></div>
            <div className={'whiteCardTitle'}><p>Design team</p></div>
              <div className={'whiteCardTextLeft'}>
                <p className={'p-small'}>They understand the user and they make the solution addictive.</p>
              </div>
            
          </div>

          <div className={'whiteCard left'}>
          <div className={'whiteCardIconLeft'}><img src={icon2} alt="" /></div>
            <div className={'whiteCardTitle'}><p>Development team</p></div>
              <div className={'whiteCardTextLeft'}>
                <p className={'p-small'}>They will build what you need. Just don’t feed them after midnight.</p>
              </div>
          </div>

          <div className={'whiteCard left'}>
          <div className={'whiteCardIconLeft'}><img src={icon3} alt="" /></div>
            <div className={'whiteCardTitle'}><p>Project managers and PO</p></div>
              <div className={'whiteCardTextLeft'}>
                <p className={'p-small'}>They’ll make sure we deliver in time or else...</p>
              </div>
            
          </div>

          <div className={'whiteCard left'}>
          <div className={'whiteCardIconLeft'}><img src={icon4} alt="" /></div>
            <div className={'whiteCardTitle'}><p>QA and testing</p></div>
              <div className={'whiteCardTextLeft'}>
                <p className={'p-small'}>They make sure everything works according to plan.</p>
              </div>
            
          </div>

          <div className={'whiteCard left'}>
          <div className={'whiteCardIconLeft'}><img src={icon5} alt="" /></div>
            <div className={'whiteCardTitle'}><p>Change management and communication strategy</p></div>
              <div className={'whiteCardTextLeft'}>
                <p className={'p-small'}>They help you get the rest of your team onboard and communicating the new solution.</p>
              </div>
          </div>
        </div>
        </div>
        </div>
        </article>

        <figure className={styles.picture + ' ' + ''}>
          <Img fluid={ data.groupPicture.childImageSharp.fluid } alt="Group Picture" />
        </figure>
        
        <article className={ 'container-small space' + styles.article }>
        <div className={'space'}></div>
        <div className={'grid-cards-2'}>
          <div>
          <h2>When you want to build a bold vision</h2>
            <p>We really like to work with our clients on the long term, to help them build a bold vision for their business.
            <br />As all industries become tech and design driven, companies need a tech partner that works with them in finding ways to stay ahead of the curve.</p>
            <h3>We want to become that partner</h3>
            <p>That’s why we always start each project thinking about the information and knowledge the solution should bring back to the business.
            <br />From that data, we are able to understand how the solution needs to evolve and how it can help keep moving the business forward.</p>
          </div>
          <div className="cardLarge">
            <div className="cardContent">
              <p className="cardHashtags">#hashtags</p>
              <p className="cardTitle">A new way of buying tickets online</p>
            </div>
          </div>
        </div>
        <div className={'space'}></div>
        <div className={'space'}></div>
      </article>
    </Layout>
  )
}

export default Services;
